.supportPortalTicketInfoTitle {
  font-size: 0.9rem;
  margin: 0;
  margin-bottom: 0.5rem;
}

.circleWithNumber {
  width: 0.9rem;
  margin-bottom: 0.5rem;
}

.circleNumberAndText {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: flex-start;
  font-size: smaller;
}

.ticketInfosBox {
  position: relative;
  width: fit-content;
  margin-top: 0.5rem;
}

.ticketInfos {
  padding: 1rem;
  padding-right: 2rem;
  width: 40rem;
  overflow: hidden;
  resize: none;
  height: 8rem;
}

.copyButton {
  position: absolute;
  top: 0;
  right: 0;
}
