.emergencyLicense {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.confirmCheckbox{
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.checkboxContainer{
  display: flex;
  flex-direction: row;
}

.preWrap {
  white-space: pre-wrap;
}