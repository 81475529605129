@import "../../../variables";
@import "../../../colors.scss";

.content {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: calc(100vh - $pageHeaderHeight);
  margin-top: $pageHeaderHeight;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: span 2;
  position: sticky;
  top: 3.125rem;
  z-index: 20;
  background-color: $appBackgroundColor;
}

.main {
  height: 100%;
  display: grid;
  grid-template-rows: auto 15rem 1fr;
  grid-template-columns: 5fr auto;
}

.pageContent {
  grid-row-start: 2;
  grid-row-end: span 2;
  grid-column-start: 1;
  padding: 0 1rem 2rem 1rem;
}

.pageInfo {
  display: block;
  position: sticky;
  top: $pageContentHeaderYPosition;
  padding-top: 0.5rem;
}

.search {
  margin-right: 1rem;
}
