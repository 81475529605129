@import "../../../colors.scss";

.title {
  margin: 0;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  align-items: flex-end;
  margin: 1rem 0 0.5rem 0;
}

.gridContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 4px;
  column-gap: 4px;
}
