.systemHeaderFields {
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  margin-bottom: 0.5rem;
  align-items: top;
}

.systemHeaderFields > h4 {
  font-weight: 500;
  font-size: small;
  margin: 0;
}
