@import "../../colors.scss";

.editInputDialog {
  /* needs absolute positioning so it is displayed as on top of the screen */
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem;
  padding-right: 0.5rem;
  border: 2px solid $inputDialogEditHighlighted;
  border-radius: 2px;
}

.inputWithButtons {
  display: flex;
  align-items: center;
}

.input {
  background-color: $inputDialogEditHighlighted;
  outline: none;
  border: none;
}

.validationError,
.validationError:focus-visible {
  outline-color: $inputDialogCancel;
  border: 1px solid $inputDialogCancel;
}

.validationErrorMessage {
  color: $inputDialogCancel;
  font-size: small;
  font-weight: 500;
  text-transform: none;
}

.okButton {
  margin-left: 0.6rem;
  margin-right: 0.4rem;
  color: $confirmGreen;
  cursor: pointer;
}

.okButton:hover {
  color: $confirmGreenHighlighted;
}

.cancelButton {
  margin-left: 0.3rem;
  color: $inputDialogCancel;
  cursor: pointer;
}

.cancelButton:hover {
  color: $inputDialogCancelHighlighted;
}
