.contractSearch {
  display: flex;
  flex-direction: row;
}

.input {
  width: 100%;
}

.buttonContainer {
  height: 2rem;
}

.searchButton {
  width: 6rem;
  height: 2rem;
}
