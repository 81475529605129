@import "../../colors.scss";

.conflictManagement {
  padding: 1rem;
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  margin-top: 0;
  font-size: 1rem;
}

.proceedButtons {
  align-self: flex-end;
}

.warningFirstConflictFix {
  margin-bottom: 0.8rem;
  color: $snp-danger-color;
  font-weight: 600;
}

.supportPortalTicketInfo {
  margin-left: 1rem;
}
