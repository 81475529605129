@import "../../../colors.scss";

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  margin-bottom: 0.8rem;
  border: 1px solid $gray3;
  padding: 0.2rem;
}

.title h1 {
  font-size: small;
  margin: 0;
}

.expertMode {
  color: $red2;
}
