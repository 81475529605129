@import "../../colors.scss";

$padding: 1rem;

.editableCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 1px solid $gray4;
  padding: $padding;
}

.spinner {
  width: 0.5rem;
}

button.confirmButton.extraClass {
  background-color: $snp-success-color;
}

button.confirmButton.extraClass:hover {
  background-color: darken($snp-success-color, 10%);
}

button.confirmButton.extraClass > span {
  color: $white;
}

button.cancelButton.extraClass {
  background-color: $snp-danger-color;
}

button.cancelButton.extraClass:hover {
  background-color: darken($snp-danger-color, 10%);
}

button.cancelButton.extraClass > span {
  color: $white;
}
