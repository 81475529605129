.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.addButtonSpacing {
  margin: 1rem 0;
}
