@import "../../../mixins.scss";

.collapse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon {
  @include collapsible;
}
