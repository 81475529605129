@import "../../../colors.scss";

.button {
  width: 30rem;
}

.displayRoles{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.displayRoles > * {
  flex: 1 1 30%;
}
