@import "../../../colors.scss";

.contractList {
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.search {
  margin-left: 15%;
  margin-right: 15%;
}
