// SNPKit - Core
@import "../node_modules/@snpjs/core/lib/css/snpkit.css";
@import "../node_modules/@snpjs/core/lib/scss/variables";

// SNPKit - CrystalBridge
@import "../node_modules/@snpjs/crystalbridge/lib/css/snpkit-crystalbridge.css";

// SNPKit - Icons
@import "../node_modules/@snpjs/icons/lib/css/snpkit-icons.css";

// SNPKit - Select
@import "../node_modules/@snpjs/select/lib/css/snpkit-select.css";

// SNPKit - Table
@import "../node_modules/@snpjs/table/lib/css/snpkit-table.css";

// Normalize
@import "../node_modules/normalize.css/normalize.css";

// Colors
@import "./colors.scss";

html {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $appBackgroundColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
