.fallback {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
