@import "../../../colors.scss";

$breadcrumbHeight: 3rem;
$breadcrumbBorderSize: $breadcrumbHeight / 2;
$colors: $cerulean2, $blue2, $blue3, $blue4, $blue5;

.breadcrumb {
  position: relative;
  height: $breadcrumbHeight;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 2rem;
}

.lastBreadcrumb {
  cursor: auto;
}

.breadcrumbWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

@for $i from 1 through length($colors) {
  .breadcrumb:nth-child(#{length($colors)}n + #{$i}) {
    background: nth($colors, $i);
    min-width: 8rem;
    text-align: center;
    font-size: large;
    font-weight: 500;

    &::after {
      border-left: 1.5rem solid nth($colors, $i);
      content: "";
      border-top: $breadcrumbBorderSize solid transparent;
      border-bottom: $breadcrumbBorderSize solid transparent;
      position: absolute;
      top: 0;
      right: calc(
        -#{$breadcrumbBorderSize} + 1px
      ); // +1px to hide the border probably from rounding error
      bottom: 0;
      z-index: 1;
    }

    & {
      @if ($i ==1) {
        //padding is only needed for the arrows, so not for the first element
        padding-left: 0;
      }

      @if ($i < 3) {
        color: $white;
      }
    }
  }
}
