@import "../../../colors.scss";

.conflictSection {
  display: flex;
  flex-direction: row;
}

.icon {
  margin-right: 0.5rem;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  margin-top: 0.2rem;
  margin-bottom: 0.8rem;
  // color: $snp-danger-color;
}
