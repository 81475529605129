@import "../../../colors.scss";

.systemLandscape {
  margin-bottom: 1rem;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  font-size: 1rem;
}

.deleteIcon:hover {
  color: $snp-danger-color;
}

.detailList {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  font-weight: bold;
}

.messageArea {
  margin-top: 1rem;
  font-family: $pt-font-family;
}

.systemLandscapeButton {
  font-weight: bold;
}
