.systemDownloadHistory {
  display: flex;
  flex-direction: column;
}

.details {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.headline {
  margin-bottom: 0.5rem;
}
