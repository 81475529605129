.carousel {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: var(--width);
  height: var(--height);
}

.body {
  width: 100%;
  height: 100%;
}
