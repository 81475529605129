@import "../../../colors.scss";

.titleSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}

.title {
  margin: 1rem 0 0 0;
  color: $blue1;
}

.explanation {
  width: 42rem;
}
