.headline {
  margin: 0.5rem;
  font-weight: 500;
}

.selection {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.selection:first {
  border: 1px solid red;
}
