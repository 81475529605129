@import "../../colors.scss";

.labeledElement {
  justify-content: end;
  display: flex;
  flex-direction: column;
}

.header {
  font-weight: 500;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.placeholder {
  color: $gray4;
}

.errorMessage {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  color: $snp-danger-color;
  margin-top: 0.25rem;
}
