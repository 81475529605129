.systemDetailsOverview {
  display: flex;
  flex-direction: column;
}

.block {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.block h4 {
  margin: 0;
  margin-bottom: 0.5rem;
}

.blockDetails {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}
