$padding: 1rem;

.card {
  //ensure that when a message area is displayed, that the card next to it stretches to 100%
  height: 100%;
}

.userAuthRole {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $padding;
}

.messageArea {
  margin-bottom: 1rem;
}

.details {
  display: flex;
  flex-direction: row;
}

.labeledInput {
  width: 30rem;
}

.label {
  font-weight: 500;
  font-size: small;
}

.detailsBody {
  margin-left: 2rem;
}

.detailsBody > h4 {
  @extend .label;
  margin: 0;
  margin-bottom: 0.3rem;
}
