@import "../../../colors.scss";

.subTitle {
  margin-bottom: 0.5rem;
}

.errorMessage {
  color: $snp-danger-color;
  margin-top: 0.5rem;
}
