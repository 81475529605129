.inputWithEditButton {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.spinner {
  width: 0.5rem;
  margin-left: 0.5rem;
}
