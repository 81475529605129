@import "../../colors.scss";

.installGuide {
  background-color: white;
  width: 80%;
  padding: 2rem 5rem;
}

th {
  background-color: $white;
  padding-left: 0.5rem;
  padding-right: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid $gray1;
}

tr:nth-child(n) {
  background-color: $white;
}

tr:nth-child(2n) {
  background-color: $light-gray5;
}

td {
  padding: 0.5rem;
  padding-right: 1rem;
}
