.systemDetailsOverviewPopover {
  margin: 0;
}

.systemDetailsOverview {
  padding: 1rem;
}

.systemDetailsOverview h3 {
  margin: 0;
}
