@import "../../colors.scss";

.button {
  width: 100%;
}

.screenCenter {
  display: block;
  margin-top: 20%;
  width: 480px;
}
