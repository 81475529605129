@import "../../../colors.scss";

.singleView {
  display: flex;
  align-self: center;
  width: calc(50% - 1rem);
  min-width: 30rem;
}

.multiView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.contractDetailsList {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
}

.contractDetailsTitle {
  font-weight: 600;
}
