@import "../../colors.scss";

$lightenBackgroundPercentage: 0%;

.messageArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
}

.error {
  background-color: lighten($snp-danger-color, $lightenBackgroundPercentage);
}

.help {
  background-color: lighten($blue5, $lightenBackgroundPercentage);
}

.info {
  // background-color: lighten($blue5, $lightenBackgroundPercentage);
  background-color: lighten($gold5, $lightenBackgroundPercentage);
}

.success {
  background-color: lighten($snp-success-color, $lightenBackgroundPercentage);
}

.warning {
  background-color: lighten($snp-warning-color, $lightenBackgroundPercentage);
}

.cursorPointer {
  cursor: pointer;
}
