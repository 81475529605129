@import "../../colors.scss";

.logo {
  margin-right: 1rem;
  height: 70%;
  cursor: pointer;
}

.title {
  font-weight: 600;
  color: $snp-primary-color;
}

.navbar {
  border-bottom: 4px solid $cerulean2 !important;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: $cerulean2;
}
