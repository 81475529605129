@import "../../../mixins.scss";

$padding: 1rem;

.systemCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 0.6rem;
  padding-left: $padding;
  padding-right: $padding;
}

.label {
  font-weight: 500;
  font-size: small;
}

.detailsHeader {
  display: flex;
  flex-direction: column;
}

.detailsHeaderForm {
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.detailsHeaderForm > h4 {
  @extend .label;
  margin: 0;
}

.detailsBody {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
}

.detailsBody > h4 {
  @extend .label;
  margin: 0;
  margin-bottom: 0.3rem;
}

.messageArea {
  margin-bottom: 1rem;
}

.systemCard .messageButton {
  margin-left: 0.3rem;
}

.spinner {
  width: 0.5rem;
}

.systemDetails {
  margin-left: 0.5rem;
  align-self: center;
  display: flex;
  column-gap: 0.5rem;
}

.systemRolesSection {
  margin-bottom: 1rem;
}

.systemRoles {
  margin-left: 1rem;
}

.conflictSection {
  margin-left: 1rem;
}

.headline {
  margin: 0.5rem 0;
}

.systemHeaderFields {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
}

.collapseIcon {
  margin-right: 0.5rem;
  align-self: center;
  @include collapsible;
}

.elementWithTooltip{
  display: block !important;
}

.systemRoleInfo {
  margin-left: 3rem;
  margin-bottom: 0.3rem;
  flex-grow: 1;
  flex-basis: 0;
}

.collapsedRoleHeader {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-weight: 500;
  font-size: small;
  display: flex;
}
