.versionSelection {
  display: flex;
  flex-direction: row;
  column-gap: 1.2rem;
}

.labeledSelect {
  width: 10rem;
}

.isLTS {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: small;
  // align-self: end;
}

.isLTS > .ltsLabel {
  margin: 0;
  margin-bottom: 0.4rem;
}
