@import "../../../colors.scss";

.landingPageInfo {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header {
  margin: 0;
  letter-spacing: 0.1rem;
  color: $gray1;
  font-size: large;
}

.subTitle {
  font-weight: 600;
  background-color: $light-gray4;
  padding: 0.5rem;
}

.icon {
  color: $gray1;
}
