.errorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.errorPageLogo {
  margin-bottom: 2rem;
}
