@import "../../../colors.scss";

.footer {
  display: flex;
  flex-direction: row;
  column-gap: 0.4rem;
  justify-content: center;
}

.circle {
  fill: none;
  stroke: $gray1;
  width: 0.75rem;
}

.filledCircle {
  fill: $gray1;
  @extend .circle;
}
