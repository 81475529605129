@import "../../../colors.scss";
@import "../../../mixins.scss";

.header {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
  align-items: center;
}

.headerElement {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.collapseIcon {
  @include collapsible;
  margin-right: 0.5rem;
}

.buttonGroup {
  display: flex;
  justify-content: end;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.licenseGrid {
  display: grid;
  grid-template-columns: 2% 83% 15%;
}

.licenseInfo {
  display: grid;
  align-items: center;
  grid-template-columns: 180px 100px 180px 100px;
  margin-bottom: 10px;
}

.licenseOptions{
  margin-top: 0.5rem;
}

.messageAreaContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;

  // width: 100rem;
}