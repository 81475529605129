@import "../../../variables";
@import "../../../colors";
@import "../../../mixins.scss";

.pageInfo {
  border-left: solid 1px $monochrome-gray2;
  display: flex;
  flex-direction: row;
  margin-right: 0.5rem;
}

.infoArea {
  width: 20rem;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $monochrome-gray2;
}

.infoAreaDetails {
  padding-right: 0.5rem;
  color: $monochrome-gray1;
}

.collapseIcon {
  @include collapsible;
}