@import "../../../../colors.scss";

$margin: 0.5rem;

.contractSearchFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputList {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.sid {
  width: 8em;
}

.client {
  width: 3rem;
}

.installNumber {
  width: 10rem;
}

.release {
  width: 4rem;
}

.isLTS {
  width: 5rem;
}

.servicePack {
  width: 5rem;
}

.hotfix {
  width: 5rem;
}

.description {
  width: 18rem;
}

@mixin systemFilter {
  cursor: pointer;
  background-color: $blue2;
  color: $white;
}

.systemFilterButtonSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.button {
  background-color: transparent;
  border: none;
  margin-left: $margin;
  margin-bottom: $margin;
  margin-top: $margin;
  padding: 0.5rem;
  &:focus {
    outline: none;
  }
  &:hover {
    @include systemFilter();
  }
}

.systemFilterSelected {
  @include systemFilter();
}
