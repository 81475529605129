@import "../../../mixins.scss";
@import "../../../colors.scss";

.body {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.navigation {
  width: 4rem;
}

.navigationActive {
  @extend .navigation;
  @include collapsible;
}

.navigationInactive {
  @extend .navigation;
  color: $light-gray3;
}

.item {
  width: 100%;
  height: 100%;
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
}
