@import "../../../colors.scss";

.breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  padding-top: 0rem;
  padding-bottom: 1rem;
}
